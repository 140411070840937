import * as React from 'react';
import clsx from 'clsx';
import { TLinkP, Link } from './Link';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Card, CardActionArea, CardContent, Theme, Grid, makeStyles } from '@material-ui/core';
import { UseStyles } from '../lib/types/mui';
import { ExternalLinkIcon, IconProp, TBaseIconProps } from './FAIcons';
import { Avatar } from './Avatar';

type StyleProps = { inline: boolean; };
type ClassKey = 'media' | 'container' | 'image' | 'content' | 'card' | 'icon' | 'optionalIcon' | 'externalIcon';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    card(props: StyleProps) {
        return { display: props.inline ? 'inline-block' : 'block' };
    },
    container: { display: 'flex', alignItems: 'stretch' },
    media: { width: 'auto', height: '100%' },
    image: {
        // height: '100%',
        // '& > div:first-child': { paddingBottom: 0 }
    },
    content: { padding: theme.spacing(4, 5) },
    icon: {
        display: 'flex',
        alignItems: 'center',
    },
    optionalIcon: {
        ...theme.typography.iconSizing(3),
        marginRight: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    externalIcon: {
        marginLeft: theme.spacing(6),
        color: theme.palette.custom.links.default.color
    }
}));

export type TBasicLinkCardP<TIconProps extends TBaseIconProps> = Pick<TLinkP, 'variant' | 'to'> & {
    // media?: GatsbyImageWithIEPolyfillProps['fixed'];
    // media2?: GatsbyImageWithIEPolyfillProps['fluid'];
    inline?: boolean;
    className?: string;
    icon?: IconProp<TIconProps>;
};

export function BasicLinkCard<TIconProps extends TBaseIconProps>(
    props: TReactFCP<TBasicLinkCardP<TIconProps>>
): TReactFCR {
    const { variant, to, icon, inline = false }: TBasicLinkCardP<TIconProps> = props;
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ inline });
    return (
        <Card className={clsx(styles.card, props.className)}>
            <CardActionArea component={Link} variant={variant} to={to} disableColor disableUnderline>
                <CardContent className={styles.content}>
                    <Grid container wrap='nowrap' alignItems='center' justify='center'>
                        {icon && (
                            <Grid className={styles.optionalIcon} item>
                                <Avatar variant='icon' icon={icon} size='lg' />
                            </Grid>
                        )}
                        <Grid item xs>{props.children}</Grid>
                        {variant === 'external' && (
                            <Grid className={styles.externalIcon} item>
                                <ExternalLinkIcon fixedWidth />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

/**
 * I'm keeping these implementations commented out because they each had their benefits and were annoyingly hard for me
 * to figure out. I don't want to lose them.

<div className={styles.container}>
    <div className={styles.media}>
        <GatsbyImage
            className={styles.image}
            objectFit='cover' objectPosition='center center' fluid={media}
            style={{ width: '100%', height: 'auto' }}
            imgStyle={{ width: '100%', height: 'auto' }}
        />
    </div>
    <CardContent className={styles.content}>
        <Typography variant='h5'>{props.children}</Typography>
    </CardContent>
</div>
<Grid container alignItems='center'>
    <Grid className={styles.media} item xs={2}>
        <div>
            {media && <GatsbyImage
                className={styles.image}
                objectFit='cover' objectPosition='center center' fixed={media}
                style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                imgStyle={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
            />}
            {media2 && <GatsbyImage
                className={styles.image}
                objectFit='cover' objectPosition='center center' fluid={media2}
                style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                imgStyle={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
            />}
        </div>
    </Grid>
    <Grid item xs={10}>
        <CardContent className={styles.content}>
            <Typography variant='h5'>{props.children}</Typography>
        </CardContent>
    </Grid>
</Grid>
 */
