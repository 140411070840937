import * as React from 'react';
import clsx from 'clsx';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Typography } from './Typography';

type StyleProps = {
    height: number;
    width: number;
};
type ClassKey = 'container' | 'iframe' | 'gutter' | 'caption';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    container(props: StyleProps) {
        return {
            position: 'relative',
            paddingBottom: `${(props.height / props.width) * 100}%`,
            height: 0,
            width: '100%',
        };
    },
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[6]
    },
    gutter: { marginBottom: theme.baseline.blockMarginBottom },
    caption: { marginTop: theme.spacing(3) }
}));

export type TYoutubeEmbedP =
    Omit<
        React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>,
        'title' | 'height' | 'width' | 'src'
    > & {
        title: string;
        height: number;
        width: number;
        src: string;
        enableGutter?: boolean;
        caption?: string;
    };

export function YoutubeEmbed(props: TReactFCP<TYoutubeEmbedP>): TReactFCR {
    const { title, height, width, src, caption, enableGutter = false, ...iframeProps }: TYoutubeEmbedP = props;
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ height, width });
    const rootContainerStyles: string = clsx({ [styles.gutter]: enableGutter });
    return (
        <div className={rootContainerStyles}>
            <div className={styles.container}>
                <iframe className={styles.iframe} src={src} title={title} {...iframeProps} />
            </div>
            {caption && (
                <Typography
                    className={styles.caption}
                    component='div'
                    variant='caption'
                    align='center'
                    color='lighter'
                >
                    {caption}
                </Typography>
            )}
        </div>
    );
}
