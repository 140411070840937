import * as React from 'react';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { SEO } from '../../components/app/SEO';
import { Page } from '../../components/app/Page';
import { Typography } from '../../components/Typography';
import { BasicLinkCard } from '../../components/BasicLinkCard';
import { IDBadgeIcon } from '../../components/FAIcons';
import { SocialLink } from '../../components/SocialLink';
import { YoutubeEmbed } from '../../components/YoutubeEmbed';
import { Link } from '../../components/Link';
import { graphql } from 'gatsby';
import { GQLDrivingPageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';

type ClassKey = 'main' | 'title' | 'experienceLinkContainer' | 'basicLink';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: '40em',
        margin: '0 auto'
    },
    title: { marginBottom: theme.spacing(6) },
    experienceLinkContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.baseline.blockMarginBottom
    },
    basicLink: { display: 'inline-block' }
}));

export type TDrivingP = {
    data: GQLDrivingPageQuery;
};

function Driving(props: TReactFCP<TDrivingP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, drivingHero }: GQLDrivingPageQuery = props.data;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={'Driving'}
                seoTitle={'Driving flat out'}
                description={`
                    Certified HPDE champion, instructor, driver, and Time Trial dabbler. The need for speed and is
                    strong with this one.
                `}
                image={{
                    src: drivingHero!.childImageSharp!.original!.src!,
                    width: drivingHero!.childImageSharp!.original!.width!,
                    height: drivingHero!.childImageSharp!.original!.height!
                }}
            />
            <div className={styles.title}>
                <Typography variant='h1' align='center'>Driving</Typography>
                <Typography variant='subtitle2' color='lighter' align='center'>My not so hobby, hobby</Typography>
            </div>
            <section>
                <Typography paragraph>
                    {`As you've probably guessed by now, I spend a lot of time at race tracks. So much over the past few
                    years that I've joined the ranks of High Performance Driving Instructors and started teaching
                    at `}
                    <Link variant='external' to='https://en.wikipedia.org/wiki/High_Performance_Driver_Education'>
                        High Performance Driving Education (HPDE)
                    </Link>
                    {` events around the U.S. It definitely requires a little
                    bit of crazy to get into a complete stranger's car and try to impart information at 100+ MPH, but
                    watching my students become drastically better drivers in less than 24 to 48 hours is priceless.`}
                </Typography>
                <Typography paragraph>
                    I've been participating in HPDE and{` `}
                    <Link variant='external' to='https://en.wikipedia.org/wiki/Time_trial'>Time Trial (TT)</Link>
                    {` `}events for more than 4 years. Keeping a logbook,
                    driver resume, and list of qualifications is crucial for each driver to be placed in the appropriate
                    skill groups and car classes across different events run by different organizations. To make it
                    easier for organizers to vet my experience I've put my resume, instructor, certifications, and any
                    competition licenses online.
                </Typography>
                <div className={styles.experienceLinkContainer}>
                    <BasicLinkCard
                        variant='external'
                        to='https://www.notion.so/Experience-027c390f0d8c4c3fa0a8ff98ddda2e39'
                        inline
                        icon={{ Component: IDBadgeIcon, props: { fixedWidth: true } }}
                    >
                        Qualifications &amp; Experience
                    </BasicLinkCard>
                </div>
                <Typography paragraph>
                    {`For those inclined to see what its like I have a few videos of my Time Trials on `}
                    <SocialLink variant='youtube' disableIcon disableBrandColor enableText enableUnderline />
                    {` and you can find more on the `}
                    <SocialLink
                        variant='youtube'
                        handle='UCAZJQz0kjGGyHn_Osw9UnPA'
                        disableIcon
                        disableBrandColor
                        enableUnderline
                    >
                        Gran Touring Motorsports
                    </SocialLink>
                    {` channel.`}
                </Typography>
                <YoutubeEmbed
                    title='NJMP Thunderbolt | 1.6L SSM Miata'
                    src='https://www.youtube.com/embed/S_i_KLJpWvo'
                    width={560}
                    height={315}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    caption='Warning, the audio is very loud!'
                    enableGutter
                />
            </section>
        </main>
    );
}

export default (props: TReactFCP<TDrivingP>): TReactFCR => <Page component={Driving} cprops={props} />;

export const query = graphql`
    query DrivingPage {
        sitePage(internalComponentName: {eq: "ComponentDriving"}) {
            path
        }
        drivingHero: file(relativePath: {eq: "images/driving/driving-hero.jpg"}) {
            childImageSharp {
                original {
                    src
                    width
                    height
                }
            }
        }
    }
`;
